/**  =====================
      Form Componant start
==========================  **/

.custom-select,
.form-control {
  background: $theme-background;
  padding: 10px 20px;
  font-size: 14px;
  height: auto;

  &.form-control-lg {
    font-size: 1.25rem;
  }

  &.form-control-sm {
    height: calc(1.8125rem + 2px);
    font-size: 0.875rem;
  }

  &.form-control-range {
    box-shadow: none;
  }

  &:focus {
    background: $theme-background;
  }

  &::-moz-placeholder {
    color: #aaaeb3;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: #aaaeb3;
  }

  &::-webkit-input-placeholder {
    color: #aaaeb3;
  }
}

/* input group start */

.input-group {
  background: $theme-background;

  .input-group-text {
    padding-left: 15px;
    padding-right: 15px;
    background: transparent;

    i {
      font-size: 20px;
    }
  }

  .custom-file-label {
    margin-bottom: 0;
  }

  .btn {
    margin-bottom: 0;
    margin-right: 0;
  }
}

.cust-file-button {
  .custom-file-label {
    height: calc(2.25rem + 8px);
    line-height: 2.5;

    &::after {
      padding: 0.775rem 0.75rem;
      height: 2.65rem;
    }
  }
}

.custom-select {
  height: calc(2.25rem + 9px);
}

/* input group End */
select.form-control:not([size]):not([multiple]) {
  height: calc(2.55rem + 2px);
}

.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]),
.input-group-lg
  > .input-group-append
  > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg
  > .input-group-prepend
  > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > select.form-control:not([size]):not([multiple]),
select.form-control-lg:not([size]):not([multiple]) {
  height: calc(3.375rem + 2px);
}

.minicolors {
  .form-control {
    padding: 6px 12px 5px 44px;
  }
}

/* form-select */

.select2-container {
  width: 100% !important;
}

.tt-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
  cursor: pointer;
}

.bootstrap-tagsinput {
  width: 100%;
}

.custom-file-label {
  height: auto;

  &:after {
    height: auto;
    padding: 0.55rem 0.75rem;
  }
}

.form-control.is-invalid,
.was-validated .form-control:invalid,
.was-validated .form-control:valid,
.form-control.is-valid {
  background-position: calc(100% - 10px) calc(0.375em + 0.4875rem);
}

.was-validated .form-control:invalid ~ .invalid-feedback {
  background: #fff;
  margin-top: 0;
  padding-top: 0.25em;
}

/**====== Form Componant end ======**/
/**  =====================
      Radio & Checked start
==========================  **/
.checkbox {
  padding: 10px 0;
  min-height: auto;
  position: relative;
  margin-right: 5px;

  input[type='checkbox'] {
    margin: 0;
    display: none;
    width: 22px;

    + .form-check-label,
    + .cr {
      padding-left: 0;

      &:before {
        content: '\e83f';
        width: 22px;
        height: 22px;
        display: inline-block;
        margin-right: 10px;
        border: 2px solid #e9eaec;
        border-radius: 3px;
        font: {
          size: 15px;
          family: 'feather';
          weight: 400;
        }
        line-height: 19px;
        vertical-align: bottom;
        text-align: center;
        background: #ffffff;
        color: transparent;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
      }
    }

    &:checked + .form-check-label:before,
    &:checked + .cr:before {
      background: #1dd5d2;
      border-color: #1dd5d2;
      color: #ffffff;
    }

    &.disabled + .form-check-label,
    &:disabled + .form-check-label,
    &.disabled + .cr,
    &:disabled + .cr {
      opacity: 0.5;
    }

    &.disabled + .form-check-label:before,
    &:disabled + .form-check-label:before,
    &.disabled + .cr:before,
    &:disabled + .cr:before {
      cursor: not-allowed;
    }
  }

  &.checkbox-fill {
    input[type='checkbox'] {
      + .form-check-label,
      + .cr {
        &:after {
          content: '';
          width: 22.5px;
          height: 22.5px;
          display: inline-block;
          margin-right: 10px;
          border: 2px solid #e9eaec;
          border-radius: 2px;
          vertical-align: bottom;
          text-align: center;
          background: transparent;
          cursor: pointer;
          transition: all 0.2s ease-in-out;
          position: absolute;
          top: 8.5px;
          left: 3px;
        }

        &:before {
          opacity: 0;
          content: '\e840';
          font-size: 27px;
          background: transparent;
        }
      }

      &:checked {
        + .form-check-label,
        + .cr {
          &:after {
            opacity: 0;
          }

          &:before {
            opacity: 1;
            background: transparent;
            color: #1dd5d2;
            border-color: transparent;
          }
        }
      }
    }
  }

  @each $value in $color-bt-name {
    $i: index($color-bt-name, $value);

    &.checkbox-#{$value}
      input[type='checkbox']:checked
      + .form-check-label:before,
    &.checkbox-#{$value} input[type='checkbox']:checked + .cr:before {
      background: nth($color-bt-color, $i);
      border-color: nth($color-bt-color, $i);
      color: #ffffff;
    }

    &.checkbox-fill.checkbox-#{$value}
      input[type='checkbox']:checked
      + .form-check-label:before,
    &.checkbox-fill.checkbox-#{$value}
      input[type='checkbox']:checked
      + .cr:before {
      background: transparent;
      color: nth($color-bt-color, $i);
      border-color: transparent;
    }
  }

  .form-check-label,
  .cr {
    cursor: pointer;
  }
}

.radio {
  padding: 10px 0;
  min-height: auto;
  position: relative;
  margin-right: 5px;

  input[type='radio'] {
    margin: 0;
    display: none;
    width: 22px;

    + .form-check-label,
    + .cr {
      padding-left: 0;

      &:after,
      &:before {
        content: '';
        display: inline-block;
        margin-right: 10px;
        border-radius: 50%;
        vertical-align: bottom;
        background: #fff;
        color: transparent;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
      }

      &:before {
        width: 22px;
        height: 22px;
        border: 2px solid #e9eaec;
      }

      &:after {
        width: 12px;
        height: 12px;
        position: absolute;
        top: 13px;
        left: 5px;
      }
    }

    &:checked {
      + .form-check-label,
      + .cr {
        &:before {
          border-color: #1dd5d2;
        }

        &:after {
          background: $theme-color;
        }
      }
    }

    &:disabled {
      + .form-check-label,
      + .cr {
        opacity: 0.5;
        cursor: not-allowed;

        &:after,
        &:before {
          cursor: not-allowed;
        }
      }
    }
  }

  &.radio-fill {
    input[type='radio'] {
      + .form-check-label,
      + .cr {
        &:after {
          width: 18px;
          height: 18px;
          top: 10px;
          left: 2px;
        }
      }
    }
  }

  @each $value in $color-bt-name {
    $i: index($color-bt-name, $value);

    &.radio-#{$value} input[type='radio']:checked + .form-check-label,
    &.radio-#{$value} input[type='radio']:checked + .cr {
      &:before {
        border-color: nth($color-bt-color, $i);
      }

      &:after {
        background: nth($color-bt-color, $i);
      }
    }
  }

  .form-check-label,
  .cr {
    cursor: pointer;
  }
}

@-moz-document url-prefix() {
  .radio input[type='radio'] + .form-check-label::after,
  .radio input[type='radio'] + .cr::after {
    top: 14px;
  }
}

.custom-controls-stacked {
  .radio {
    input[type='radio'] {
      + .form-check-label,
      + .cr {
        &:after {
          top: 15px;
        }
      }
    }
  }
}

.radio,
.checkbox {
  .form-check {
    padding-left: 0;
    margin-bottom: 6px;

    input[type='radio'] + .form-check-label:after {
      top: 5px;
    }
  }
}

/**====== Radio & Checked end ======**/
