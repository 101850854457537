/*
* Alert Mixins
*/
@use "sass:math";
@mixin alert-variant-pc($background, $border, $color) {
  color: $color;
  border-color: $border;
  hr {
    border-top-color: darken($border, 5%);
  }

  .alert-link {
    color: darken($color, 10%);
  }
}

.swal-footer {
  text-align: center;
}

/*
* Button Mixins
*/
@mixin button-variant-pc(
  $background,
  $border,
  $hover-background: darken($background, 7.5%),
  $hover-border: darken($border, 10%),
  $active-background: darken($background, 10%),
  $active-border: darken($border, 12.5%)
) {
  color: color-yiq($background);
  background-color: $border;
  border-color: $border;

  &:hover {
    color: color-yiq($hover-background);
    background-color: $hover-background;
    border-color: $hover-border;
  }

  &.focus,
  &:focus {
  }

  &.disabled,
  &:disabled {
    color: color-yiq($background);
    background-color: $background;
    border-color: $border;
  }

  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  .show > &.dropdown-toggle {
    color: color-yiq($active-background);
    background-color: $active-background;
    border-color: $active-border;

    &:focus {
    }
  }
}

@mixin button-outline-variant-pc(
  $color,
  $color-hover: color-yiq($color),
  $active-background: $color,
  $active-border: $color
) {
  color: $color;
  background-color: transparent;
  background-image: none;
  border-color: $color;

  &:hover {
    color: color-yiq($color);
    background-color: $active-background;
    border-color: $active-border;
  }

  &.focus,
  &:focus {
  }

  &.disabled,
  &:disabled {
    color: $color;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  .show > &.dropdown-toggle {
    color: color-yiq($active-background);
    background-color: $active-background;
    border-color: $active-border;

    &:focus {
    }
  }
}

@mixin button-glow-variant-pc(
  $color,
  $color-hover: color-yiq($color),
  $active-background: $color,
  $active-border: $color
) {
  box-shadow: 0 1px 6px 2px transparentize($color, 0.44),
    0 6px 11px 2px transparentize($color, 0.8);
  &:hover {
    box-shadow: 0 1px 4px 2px transparentize($color, 0.44),
      0 4px 9px 2px transparentize($color, 0.9);
  }
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active:focus,
  .show > &.dropdown-toggle,
  &.active,
  &:focus {
    box-shadow: none;
  }
}

/*
* Pagination Size mixin
*/

@mixin pagination-size(
  $padding-y,
  $padding-x,
  $font-size,
  $line-height,
  $border-radius
) {
  .page-link {
    padding: $padding-y $padding-x;
    font-size: $font-size;
    line-height: $line-height;
  }

  .page-item {
    &:first-child {
      .page-link {
        @include border-left-radius($border-radius);
      }
    }

    &:last-child {
      .page-link {
        @include border-right-radius($border-radius);
      }
    }
  }
}

/**
* Theme Mixins
*/
@function color-yiq($color) {
  $r: red($color);
  $g: green($color);
  $b: blue($color);
  $yiq: math.div((($r * 299) + ($g * 587) + ($b * 114)), 1000);
  @if ($yiq >= $yiq-contrasted-threshold) {
    @return $yiq-dark-color;
  } @else {
    @return $yiq-light-color;
  }
}

@function theme-color($key: 'primary') {
  @return map-get($theme-colors, $key);
}

@function theme-color-level($color-name: 'primary', $level: 0) {
  $color: theme-color($color-name);
  $color-base: if($level > 0, $yiq-dark-color, $yiq-light-color);
  $level: abs($level);
  @return mix($color-base, $color, $level * $theme-color-interval);
}
